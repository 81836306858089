<template>
  <div class="c-task-config-view-dialog">
    <el-dialog
      v-el-drag-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="visible"
      @dragDialog="handleDrag">
      <section>
        <el-form ref="editItem" label-width="100px">

          <el-form-item label="规则名称">
            <el-input clearable v-model="editItem.commonRuleName"></el-input>
          </el-form-item>


          <el-form-item label="规则类型: ">
            <el-select clearable v-model="editItem.commonRuleType" @change="onRuleTypeChange" placeholder="请选择" style="width: 100%;">
              <el-option
                v-for="item in commonRuleTypeOptions"
                :key="item.key"
                :label="item.value"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="限定天数">
            <el-input type="number" clearable v-model="editItem.commonRuleDay"></el-input>
          </el-form-item>

          <el-form-item label="规则说明: ">
            <el-input disabled v-model="editItem.commonRuleRemark"></el-input>
          </el-form-item>

          <el-form-item  label="适用客户类型">
            <el-select  clearable v-model="editItem.commonRuleClientType" placeholder="请选择" style="width: 100%;">
              <el-option
                v-for="item in commonRuleClientOptions"
                :key="item.key"
                :label="item.value"
                :value="item.key">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item style="text-align: right">
            <el-button type="primary" @click="onSave" :style="{ display: visibleLine }">确定</el-button>
            <el-button @click="visible = false">取消</el-button>
          </el-form-item>

        </el-form>
      </section>

    </el-dialog>
  </div>
</template>
<script>
import elDragDialog from '@/directive/el-drag-dialog' // base on element-ui

import {commonRuleClientOptions, commonRuleTypeOptions} from "./config";
import {editItemMap} from "./dataMap";

export default {
  name: 'commonRules',
  directives: {
    elDragDialog
  },
  data() {
    return {
      visibleLine: '', //隐藏
      visible: false,
      title: '新增规则',
      editItem: {
        id: 0,
        commonRuleName: '',
        commonRuleType: '',
        commonRuleRemark: '',
        commonRuleDay: '',
        commonRuleClientType: '',
      },
      commonRuleClientOptions: commonRuleClientOptions,
      commonRuleTypeOptions: commonRuleTypeOptions,
    }
  },
  methods: {
    onOpen(data) {
      this.isEdit = data !== null
      this.title = data !== null ? '编辑属性' : '新增属性'
      this.visibleLine=''
      if (data === null) {
        Object.assign(this.$data.editItem, this.$options.data().editItem)
      } else {
      // if(data.IsPreset)
      // {
      //   this.visibleLine='none'
      // }
        Object.keys(this.editItem).forEach(key => {
          this.editItem[key] = data[key]
        })
      }
      this.visible = true
    },

    onRuleTypeChange(val) {
      if(val) {
        const selectItem = commonRuleTypeOptions.find(item => item.value === val)
        if(selectItem) {
          this.editItem.commonRuleRemark = selectItem.remark
        }
      } else {
        this.editItem.commonRuleRemark = ''
      }
    },

    onSave() {
      let params = this.setDataMap(this.editItem, editItemMap, 'save')
      this.$emit('onEditConfig', params)
      this.visible = false
    },
    handleDrag() {

    }
  },
  created() {

  }
}
</script>
<style lang="scss">
.c-task-config-view-dialog {
  .el-dialog {
    max-width: 500px !important;
    min-width: 400px !important;
  }
}

</style>

