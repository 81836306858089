// 属性列头配置
const commonRuleClientOptions = [
  {key: 'AddInquiry', value: '新增询价客户'},
  {key: 'Commonly', value: '一般客户'},
  {key: 'StressFollow', value: '重点跟进客户'},
  {key: 'StressProtect', value: '重点保护客户'},
  {key: 'Assist', value: '协助客户'},
  {key: 'Subordinate', value: '下属客户'}
]
const columns = [
  {
    field: 'RowIndex',
    headerName: '序号',
    width: 70,
    cellClass: 't-c'
  },
  {
    field: 'commonRuleName',
    headerName: '规则名称',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'commonRuleType',
    headerName: '规则类型',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'commonRuleRemark',
    headerName: '规则说明',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'commonRuleDay',
    headerName: '限定天数',
    editable: false,
    cellClass: 't-c'
  },
  {
    field: 'commonRuleClientType',
    headerName: '适用客户类型',
    editable: false,
    cellClass: 't-c',
    valueFormatter(params) {
      const value = params.value
      let content = ''
      const item = commonRuleClientOptions.find(item => item.key === value)
		    if (!item)
		    		return ''
      content = item.value
      return content
    },
  },
  {
    field: 'operation',
    headerName: '操作',
    editable: false,
    width: 100,
    cellClass: 't-c',
    cellRendererFramework: 'GridCellButtonRender',
  }
]

const commonRuleTypeOptions = [
  {key: '未询价', value: '未询价', remark: '在规定天数内，未询价的客户，自动掉公海。'},
  {key: '未跟进', value: '未跟进', remark: '在规定天数内，没跟进记录的客户，自动掉公海。'},
  {key: '未下单', value: '未下单', remark: '在规定天数内，没下单记录的客户，自动进公海，最后1天可申请延期审批'}
]




export {
  columns,
  commonRuleTypeOptions,
  commonRuleClientOptions
}
