<!--标签维护-->
<template>
  <div class="m-grid p-common-rule">
    <section class="m-grid-search">
      <znl-input
        border
        width="150px"
        placeholder="搜索规则名称"
        clearable
        class="m-margin--right-10"
        v-model="searchData.commonRuleName"
      >
      </znl-input>
      <znl-input
        border
        width="150px"
        type="text"
        clearable
        :select-options="commonRuleTypeOptions"
        placeholder="搜索规则类型"
        form-type="select"
        v-model="searchData.commonRuleType"
        class="m-margin--right-10"
      >
      </znl-input>
      <el-button class="m-grid-btn" type="primary" @click="onSearch()"
        >查询</el-button
      >
      <el-button class="m-grid-btn" type="primary" @click="onAdd" v-can="'config_common_rule:add'"
        >新增</el-button
      >
    </section>
    <section class="m-grid-list">
      <grid
        ref="list"
        :columns="columns"
        :datas="itemSource"
        :onPageChanged="onSearch"
        :page-index="pagination.page"
        :page-size="pagination.size"
        :selection="false"
        :total-count="pagination.total"
        headerHeight="35"
        type="base"
      >
      </grid>
    </section>

    <common-rule-edit
      ref="editView"
      @onEditConfig="onEditConfig"
    ></common-rule-edit>
  </div>
</template>

<script>
import {
  columns,
  commonRuleTypeOptions,
  commonRuleClientOptions,
} from "./modules/commonRule/config";
import CommonRuleEdit from "./modules/commonRule/CommonRuleEdit";
import { listDataMap, searchDataMap } from "./modules/commonRule/dataMap";
import {
  getCommonRuleList,
  saveCommonRule,
  editCommonRule,
  deleteCommonRule,
} from "@/api/config";

export default {
  name: "configCommonRule",
  components: {
    CommonRuleEdit,
  },
  data() {
    return {
      columns: columns,
      itemSource: [],
      pagination: {
        page: 1,
        size: 20,
        total: 0,
      },
      searchData: {
        commonRuleName: null,
        commonRuleType: null,
      },
      commonRuleTypeOptions: commonRuleTypeOptions,
      commonRuleClientOptions: commonRuleClientOptions,
    };
  },
  methods: {
    async onSearch(page = 1) {
      const { pagination, searchData } = this;
      const sendData = {
        Page: page,
        Limit: pagination.size,
      };

      Object.entries(searchDataMap).forEach(([key, value]) => {
        sendData[value] = searchData[key];
      });

      const data = await getCommonRuleList(sendData); // 请求接口
      pagination.page = data.Page;
      pagination.total = data.Total;
      const items = data.Items;

      let newItems = [];

      if (items.length) {
        newItems = this.getListData(items, listDataMap);
        newItems.forEach((item, index) => {
          item.RowIndex = index + 1;
        });
      }

      this.itemSource = newItems;
    },

    async onEditConfig(data) {
      let { isEdit } = this;
      let message = "";
      if (isEdit) {
        await editCommonRule(data);
        message = "标签编辑成功";
      } else {
        await saveCommonRule(data);
        message = "标签新增成功";
      }
      this.$message.success(message);
      await this.onSearch(this.pagination.page);
    },
    // 表格组件处理
    // 初始化列表
    initColumns() {
      const self = this;
      const OriginColumn = columns.find((item) => item.field === "operation");
      if (OriginColumn) {
        OriginColumn.cellRendererParams = {
          buttons: self.setButtons,
        };
      }
      this.$refs.list.changeColumns(columns);
    },
    setButtons() {
      let buttons = [];
      let self = this;
      const editBtn = {
        text: "编辑",
        click(params) {
          console.log(params)
          
          self.onOpenEdit(params.data);
        },
      };

      const deleteBtn = {
        text: "删除",
        click(params) {
          self.onOpenDelete(params.data);
        },
      };

      if(this.can('config_common_rule:edit')) {
        buttons.push(editBtn);
      }
      if(this.can('config_common_rule:delete')) {
        buttons.push(deleteBtn);
      }

      return buttons;
    },
    onOpenDelete(data) {
      console.log(data)
      const params = {
        RuleId: Number(data.id),
      };

      this.$confirm("此操作将永久删除公海规则, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteCommonRule(params);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          await this.onSearch();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    async onOpenEdit(data) {
      this.isEdit = true;
      this.$refs.editView.onOpen(data);
    },
    onAdd() {
      this.isEdit = false;
      this.$refs.editView.onOpen(null);
    },
    onEdit(data) {
      this.isEdit = true;
      this.$refs.editView.onOpen(data);
    },
  },
  created() {
    this.onSearch();
  },
  mounted() {
    this.initColumns();
  },
  watch: {},
};
</script>



