const listDataMap = {
  id: 'RuleID',
  commonRuleName: 'RuleName',
  commonRuleType: 'RuleType',
  commonRuleRemark: 'RuleDescription',
  commonRuleDay: 'LimitDays',
  commonRuleClientType: 'CustomerType',
  IsPreset:'IsPreset'
}

const searchDataMap = {
  commonRuleName: "RuleName",
  commonRuleType: "RuleType"
}

const editItemMap = {
  id: 'RuleID',
  commonRuleName: 'RuleName',
  commonRuleType: 'RuleType',
  commonRuleRemark: 'RuleDescription',
  commonRuleDay: 'LimitDays',
  commonRuleClientType: 'CustomerType',
}


export {
  listDataMap,
  searchDataMap,
  editItemMap
}
